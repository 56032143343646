const disableAlert = () => {
  alert('Função desabilitada para suas configurações')
}

const ruralLicenseAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}
const configureAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}

const configureFinanceiroAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}
export default [
  {
    id: 'principal',
    menuName: 'Principal',
    components: [
      {
        component: 'Minha conta',
        features: [
          {name: 'Dashboard', icon: 'dashboard', href: '/#/'},
          {name: 'Meu perfil', icon: 'perfil', href: '/#/perfil'},
          {name: 'Mensagens', icon: 'mensagens', tip: null, href: '/#/perfil/mensagens'},
          {name: 'Configurações', icon: 'config', href: '/#/perfil/configuracoes'},
        ]
      },
      /*{
        component: 'Intranet',
        features: [
          {name: 'Social Network', icon: 'network', clickEvent: disableAlert},
          {name: 'Base de conhecimento', icon: 'knowledge', clickEvent: disableAlert}
        ]
      },*/
      {
        component: 'Colaborativo',
        roles: ['ROLE_GESTAO'],
        acl: ['gestao/task/l'],
        features: [
          {name: 'Tarefas', icon: 'tasks', href: '/#/sl/tarefas'},
          // {name: 'Minhas metas', icon: 'metas', clickEvent: configureAlert},
          // {name: 'Arquivos', icon: 'files', clickEvent: configureAlert}
        ]
      }
    ]
  },
  {
    id: 'gestao',
    menuName: 'Gestão',
    roles: ['ROLE_GESTAO'],
    acl: ['gestao/*'],
    components: [
      {
        component: 'Gestão',
        features: [
          {name: 'Pessoas', icon: 'user', href: '/#/pessoas', roles: ['ROLE_GESTAO'], acl: ['gestao/pessoas/l']},
          {name: 'Tarefas', icon: 'tasks', href: '/#/sl/tarefas', roles: ['ROLE_GESTAO'], acl: ['gestao/task/l']},
        ]
      }
    ]
  },
  {
    id: 'crm',
    menuName: 'CRM',
    roles: ['ROLE_CRM'],
    acl: ['crm/*'],
    components: [
      {
        component: 'Marketing',
        features: [
          {name: 'Campanhas', icon: 'mkt', href: "/#/marketing/campanhas", roles: ['ROLE_CRM'], acl: ['crm/mkt/l/l']}
        ]
      }
    ]
  },
  {
    id: 'administrar-site',
    menuName: 'Sites',
    roles: ['ROLE_WEBSITE'],
    acl: ['website/*'],
    components: [
      {
        component: 'Configurações',
        features: [
          {name: 'Sites', icon: 'monitor', href: "/#/configuracoes/sites", roles: ['ROLE_WEBSITE'], acl: ['website/s']},
          {name: 'Menus', icon: 'config', href: "/#/website/menus", roles: ['ROLE_WEBSITE'], acl: ['website/menu/s']},
        ]
      },
      {
        component: 'Conteúdo Sites',
        roles: ['ROLE_WEBSITE'],
        acl: ['website/*'],
        features: [
          {name: 'Banners', icon: 'files', href: "/#/website/banners", roles: ['ROLE_WEBSITE'], acl: ['website/banner/s']},
          {name: 'Popup', icon: 'files', href: "/#/website/banners?type=popups", roles: ['ROLE_WEBSITE'], acl: ['website/banner/s']},
          {name: 'Páginas', icon: 'template', href: "/#/website/paginas", roles: ['ROLE_WEBSITE'], acl: ['website/conteudo/s']},
        ]
      },
      {
        component: 'Blog', roles: ['ROLE_WEBSITE'], acl: ['website/blog/l'],
        features: [
          {name: 'Publicações', icon: 'template', href: "/#/blog/posts", roles: ['ROLE_WEBSITE'], acl: ['website/blog/l']},
          {name: 'Categorias', icon: 'metas', href: "/#/website/menus", roles: ['ROLE_WEBSITE'], acl: ['website/menu/s']},
        ]
      }
    ]
  },
  {
    id: 'financeiro',
    menuName: 'Financeiro',
    roles: ['ROLE_FINANCEIRO'],
    components: [
      {
        component: 'Financeiro',
        features: [
          {name: 'Resumo', icon: 'reports', href: '/#/financeiro', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/mov/l']},
          {name: 'Contas a Pagar', icon: 'pay1', href: '/#/financeiro/contas#pagar', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/mov/l']},
          {name: 'Contas a Receber', icon: 'pay2', href: '/#/financeiro/contas#receber', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/mov/l']},
          {name: 'Extrato', icon: 'invoice', href: '/#/financeiro/contas#extrato', roles: ['ROLE_FINANCEIRO']},
        ]
      },
      {
        component: 'Contabilidade',
        features: [
          {name: 'Plano de Contas', icon: 'charts', href: '/#/financeiro/categorias', roles: ['ROLE_FINANCEIRO']},
          // {name: 'Orçamento Anual', icon: 'finalcialbook', clickEvent: configureAlert},
          // {name: 'NF-e recebidas', icon: 'financialbook', clickEvent: configureFinanceiroAlert},
          // {name: 'Contratos', icon: 'contract', clickEvent: configureFinanceiroAlert}, @TODO
        ]
      },
      {
        component: 'Cadastros',
        right: true,
        features: [
          {name: 'Fornecedores', icon: 'userwork', href: "/#/pessoas?paper=fornecedor", roles: ['ROLE_GESTAO'], acl: ['gestao/pessoas/l']},
          {name: 'Contas', icon: 'bank', href: '/#/cadastro/bancos', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/banco/l']},
          {name: 'Cento de Custo', icon: 'centercost', href: '/#/financeiro/cc', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/cc/l']},
        ]
      },
    ]
  },
  /*{
    id: 'rh',
    menuName: 'RH',
    components: []
  },*/
  /*{
    id: 'relatorios',
    menuName: 'Relatórios',
    roles: ['ROLE_RELATORIOS'],
    acl: ['relatorios/!*'],
    components: [
      {
        component: 'Emissão',
        features: [
          {name: 'Relatórios Gerenciais', icon: 'reports', href: "/#/relatorios", roles: ['ROLE_RELATORIOS']},
        ]
      },
      {
        component: 'Auditoria e Logs',
        features: [
          {name: 'Log Operacional', icon: 'log', href: '/#/relatorios/operacional/logs', roles: ['ROLE_RELATORIOS', 'ROLE_CONFIG'], acl: ['config/log/l']},
          {name: 'API Error', icon: 'api', clickEvent: configureAlert},
          {name: 'Comunicação Geral', icon: 'chat', clickEvent: configureAlert}
        ]
      }
    ]
  },*/
  /*{
    id: 'configuracoes',
    menuName: 'Configurações',
    roles: ['ROLE_CONFIG'],
    components: [
      {
        component: 'Cadastro Básico',
        features: [
          {name: 'Cadastro Básico', icon: 'register', href: "/#/cadastro"},
          {name: 'Templates e Documentos', icon: 'template', href: "/#/documentos"},
          {name: 'Variáveis', icon: 'archive', href: "/#/configuracoes/variaveis"},
          {name: 'Taxas', icon: 'percent', href: "/#/configuracoes/taxas"}
        ]
      },
      {
        component: 'Administração',
        features: [
          {name: 'Usuários', icon: 'user', href: "/#/configuracoes/usuarios"},
          {name: 'Sites', icon: 'monitor', href: "/#/configuracoes/sites"},
          {name: 'Formulários', icon: 'metas', href: "/#/forms"},
        ]
      },
      {
        component: 'Sistema',
        features: [
          {name: 'Importação', icon: 'files', href: "/#/configuracoes/importador"},
          {name: 'Configurações Globais', icon: 'config', href: "/#/configuracoes/globais"},
        ]
      },
      {
        component: 'Ajuda',
        right: true,
        hideLabel: true,
        features: [
          {name: 'Suporte', icon: 'contact', href: '/#/suporte'},
        ]
      }
    ]
  }*/
]
