<template>
  <footer>
    <div class="line-design"></div>
    <div class="footer-content">
      <div class="cont-left">
        <div class="cont-logo-footer">
          <a href="#" class="logo-footer">FENAJU - Federação Nacional das Juntas Comerciais</a>
        </div>
        <div class="c-copy">
          <p>© 2020 - 2024 - fenaju.org.br - Todos os direitos reservados.</p>
          <a href="https://fenaju.com.br/lgpd" target="_blank">Política de Privacidade e Proteção de Dados</a>
          <p>Desenvolvido por InnLei.org.br</p>
        </div>
      </div>
      <div class="cont-right">
        <p>Setor de Autarquias Sul, Quadra 02, Lote 01/A s/n.<br>Brasília - DF, 70070-020 - Brasil</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LoginFooter',
  methods: {
    showPolicy () {
      this.appdoc.define('texto.politica-privacidade').open()
    },
    useTerms () {
      this.appdoc.define('texto.termos-de-uso').open()
    }
  }
}
</script>
