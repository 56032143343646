export default [
    {
        path: '/suporte',
        component: () => import('./cliente/Page'),
        name: 'cliente.suporte.page',
        children: [
            {path: '', name: 'suporte.cliente.dashboard', component: () => import('./cliente/components/Dashboard')},
            {path: 'tarefas', name: 'suporte.cliente.tarefas', component: () => import('./cliente/components/Tarefas')},
            {path: ':id', name: 'suporte.cliente.tarefa', component: () => import('./cliente/components/Tarefa')},
        ]
    },
    {
        path: '/console/suporte',
        component: () => import('./components/Page'),
        name: 'suporte.page',
        children: [
            {path: '', name: 'suporte.dashboard', component: () => import('./components/Dashboard')},
            {
                path: 'projetos', name: 'suporte.projetos', component: () => import('./components/Projetos'),
            },
            {
                path: 'projetos/:id',
                name: 'suporte.projetos.projeto.page',
                component: () => import('./components/projetos/ProjetoPage'),
                children: [
                    {
                        path: '',
                        name: 'suporte.projetos.projeto',
                        component: () => import('./components/projetos/Projeto')
                    },
                    {
                        path: 'filas/:fila',
                        name: 'suporte.projetos.projeto.fila',
                        component: () => import('./components/projetos/Projeto')
                    },
                    {
                        path: 'tarefas/:tarefa',
                        name: 'suporte.projeto.tarefa',
                        component: () => import('./components/task/Tarefa')
                    },
                    {
                        path: 'relatorios',
                        name: 'suporte.projeto.relatorios',
                        component: () => import('./components/projetos/Relatorios')
                    },
                    {
                        path: 'membros',
                        name: 'suporte.projeto.membros',
                        component: () => import('./components/projetos/Membros')
                    }
                ]
            },
            {
                path: 'tarefas', name: 'suporte.tarefas.page', component: () => import('./components/task/TarefasPage'),
                children: [
                    {
                        path: '', name: 'suporte.tarefas', component: () => import('./components/Tarefas'),
                        children: [
                            {
                                path: ':tarefa',
                                name: 'suporte.tarefa',
                                component: () => import('./components/task/Tarefa')
                            },
                            {
                                path: 'filas/:fila',
                                name: 'suporte.tarefas.fila',
                                component: () => import('./components/task/Fila'),
                                children: [
                                    {
                                        path: 'tarefa/:tarefa',
                                        name: 'suporte.tarefas.fila.tarefa',
                                        component: () => import('./components/task/Tarefa')
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'relatorios',
                        name: 'suporte.tarefas.relatorios',
                        component: () => import('./components/task/Relatorios')
                    },
                ]
            },
            {path: 'clientes', name: 'suporte.clientes', component: () => import('./components/Clientes')}
            // {path: '/tasks/:id', name: 'task.show', component: () => import('./components/OpenTask')}
        ]
    }]
