<script>
import {UBtn, UField, UInput, UAjaxBar, UCheckbox} from 'uloc-vue'
import LoginMixin from '../login.mixin.js'
import LoginFooter from './LoginFooter.vue'

export default {
  name: 'Auth',
  inject: ['appRoot'],
  mixins: [LoginMixin],
  components: {LoginFooter, UAjaxBar, UBtn, UCheckbox, UField, UInput},
  props: {},
  data() {
    return {
      loginErrorData: null,
      isSubmiting: false
    }
  },
  beforeCreate() {
    this.lang = this.$uloc ? this.$uloc.i18n.lang : 'pt-BR' // TODO: Desenvolver próprio sistema de línguas do Auth
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    __doLogin() {
      this.$refs.bar.start()
      this.isSubmiting = true
      this.doLogin()
          .then((r) => {
            if (this.$route.query.redirect && this.$route.query.redirect !== '/logout') {
              this.$router.push({path: this.$route.query.redirect})
            } else {
              this.$router.push('/')
            }
            this.isSubmiting = false
          })
          .catch((err) => {
            this.isSubmiting = false
            console.log('ERRO LOGIN')
            console.log('Login error', err)
            this.loginErrorData = err.message || 'Ocorreu algum erro no login'
            this.$refs.bar.stop()
            this.$uloc.notify({
              type: 'negative',
              message: this.loginErrorData
            })
          })
    }
  },
  computed: {
    isLogged: function () {
      return this.$uloc ? this.$uloc.auth.logged : this.$auth.logged
    }
  }
}
</script>

<template>
  <div class="sl-auth" v-if="!isLogged">
    <div class="sl-login animated">
      <div class="login-form">
        <div v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('erp.login.logo') && $appConfig.getConfig('erp.login.logo') && $appConfig.getConfig('erp.login.logo')" class="login-header">
          <div class="lh-logo full">
            <img :src="$appConfig && $appConfig.getConfig('erp.login.logo')" />
          </div>
        </div>
        <div v-else-if="appRoot.installed && $appConfig" class="login-header">
            <!-- <div v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.license') && $appConfig.getConfig('empresa.license') === 'enterprise' && $appConfig.isConfigNotEmpty('empresa.logomarca')" class="lh-logo full">
              <a target="_blank"><img :src="$appConfig.getConfig('empresa.logomarca')" /></a>
            </div> -->
            <div class="lh-logo">
              <a href="https://fenaju.org.br" target="_blank"><img src="@/assets/images/fenaju_logo.png" /></a>
            </div>
        </div>
        <form class="auth-form" method="post" @submit.prevent="__doLogin">
          <div class="block m-t-lg"></div>
          <div class="login-form-pre-header">
            <div class="login-lbl">Conectar ao ERP</div>
          </div>
          <u-field
              :label-width="12"
              label="Login"
          >
            <u-input required type="text" placeholder="Apelido, Login, E-mail ou CPF" v-model="user.email" autofocus class="login-input" hide-underline/>
          </u-field>
          <u-field
              :label-width="12"
              label="Senha de acesso"
              class="m-t-lg"
          >
            <u-input required type="password" v-model="user.password" class="login-input" hide-underline/>
          </u-field>

          <div class="text-left m-t-lg text-grey-7">
            <u-checkbox label="Lembrar-me deste computador" v-model="rememberMe"></u-checkbox>
          </div>
          <u-btn :disabled="isSubmiting" type="submit" color="green" size="md"
                 label="Entrar"
                 class="w-full m-t-md btn-login" no-caps />
          <a class="btn-resetpw">Esqueci minha senha</a>        
        </form>
          <div class="text">ou</div>
        <a href="https://fenaju.com.br" class="btn-voltar">Retornar ao site</a>
      </div>
      <login-footer/>
    </div>
    <div class="sl-login-banner">
<!--      <img src="@/assets/images/erp-login-img1.png" />-->
    </div>
    <u-ajax-bar ref="bar" color="primary"/>
  </div>
</template>
