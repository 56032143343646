import dashboard from './dashboard/routes'
// import remocao from './remocao/routes'
// import bem from './bem/routes'
// import bem2 from './bem2/routes'
// import leiloes from './leiloes/routes'
// import pda from './pda/routes'
import arrematante from './arrematante/routes'
// import comitente from './comitente/routes'
import cadastro from './cadastro/routes'
// import processos from './processos/routes'
import usuario from './usuario/routes'
// import patio from './patio/routes'
// import reboquista from './reboquista/routes'
// import reboques from './reboques/routes'
// import vistoriador from './vistoriador/routes'
// import taxas from './taxas/routes'
// import templates from './templates/routes'
// import variaveis from './variaveis/routes'
import globalconfig from './globalconfig/routes'
import perfil from './perfil/routes'
import website from './website/routes'
// import sites from './sites/routes'
// import importador from './importador/routes'
// import integraGrupoPorto from './integra-grupo-porto/routes'
// import integraResale from './integra-resale/routes'
import blog from './blog/routes'
import marketing from './marketing/routes'
import sltarefas from './sltarefas/routes'
import relatorios from './relatorios/routes'
import pessoas from './pessoa/routes'
import financeiro from './financeiro/routes'
import documentos from './documentos/routes'
import validadeDocumentos from './validadeDocumentos/routes'
// import controleRgi from './controleRgi/routes'
import negocios from './negocio/routes'
// import vistoria from './vistoria/routes'
import callcenter from './callcenter/routes'
// import propostasVendaDireta from './propostas-venda-direta/routes'
// import servidor from './gerencia/servidor/routes'
import parceiros from './parceiros/routes'
import suporte from './suporte/routes'
// import clientes from './clientes/routes'
// import filaVendasLeilao from './leilao-venda-fila-espera/routes'
// import entregaItens from './leilao-entrega-itens/routes'
// import notas from './leilao-notas/routes'
import forms from './forms/routes'
import marketplace from './marketplace/routes'
import fejanu from './fenaju/routes'

/**
 * Gerencia
 */
//import cliente from "@/components/gerencia/cliente/routes"
// import console from "@/components/console/routes"

export default [
    ...dashboard,
    //...remocao,
    //...bem,
    //...bem2,
    //...leiloes,
    //...pda,
    ...arrematante,
    //...comitente,
    ...cadastro,
    //...processos,
    ...usuario,
    //...patio,
    //...reboquista,
    //...reboques,
    //...vistoriador,
    // ...taxas,
    // ...templates,
    // ...variaveis,
    ...globalconfig,
    ...perfil,
    ...website,
    // ...sites,
    // ...importador,
    //...integraGrupoPorto,
    //...integraResale,
    ...blog,
    ...marketing,
    ...sltarefas,
    ...relatorios,
    ...pessoas,
    ...financeiro,
    ...documentos,
    //...validadeDocumentos,
    ...negocios,
    //...vistoria,
    ...callcenter,
    //...propostasVendaDireta,
    // Gerencia
    //...cliente,
    //...servidor,
    ...parceiros,
    ...suporte,
    //...filaVendasLeilao,
    //...clientes,
    //...controleRgi,
    //...entregaItens,
    //...notas,
    ...forms,
    //...console,
    ...marketplace,
    ...fejanu
]
