// import Main from './Main'
// import Configuracoes from './components/Configuracoes'
// import Page from "@/components/globalconfig/layout/Layout"
import taxas from "@/components/taxas/routes"
import templates from "@/components/templates/routes"
import variaveis from "@/components/variaveis/routes"
// import usuario from "@/components/usuario/routes"
import sites from "@/components/sites/routes"
import importador from "@/components/importador/routes"

export default [
    {
        path: '/configuracoes', component: () => import('@/components/globalconfig/layout/Layout'), name: 'config.page', children: [
            {
                path: 'globais',
                component: () => import('./components/Configuracoes'),
                name: 'configuracoesglobais.main',
                children: [
                    {
                        path: 'empresa',
                        name: 'config.empresa',
                    },
                    {
                        path: 'caller',
                        name: 'config.caller',
                    },
                    {
                        path: 'lance',
                        name: 'config.lance',
                    },
                    {
                        path: 'bem',
                        name: 'config.bem',
                    },
                    {
                        path: 'clientes',
                        name: 'config.clientes'
                    },
                    {
                        path: 'comunicacao',
                        name: 'config.comunicacao'
                    },
                    {
                        path: 'robo',
                        name: 'config.robo'
                    },
                    {
                        path: 'financeiro',
                        name: 'config.financeiro'
                    },
                    {
                        path: 'forms',
                        name: 'config.forms'
                    },
                    {
                        path: 'processos',
                        name: 'config.processos'
                    },
                    {
                        path: 'tarefas',
                        name: 'config.tarefas'
                    },
                    {
                        path: 'projetos',
                        name: 'config.projetos'
                    },
                    {
                        path: 'marketing',
                        name: 'config.marketing'
                    },
                    {
                        path: 'relatorios',
                        name: 'config.relatorios'
                    },
                    {
                        path: 'interface',
                        name: 'config.interface'
                    },
                    {
                        path: 'apps',
                        name: 'config.apps'
                    },
                    {
                        path: 'integra-comitentes',
                        name: 'config.integra-comitentes'
                    },
                    {
                        path: 'integra-imovelweb',
                        name: 'config.integra-imovelweb'
                    },
                    {
                        path: 'integra-resale',
                        name: 'config.integra-resale'
                    },
                    {
                        path: 'integra-santander-imoveis',
                        name: 'config.integra-santander-imoveis'
                    },
                    {
                        path: 'integra-robo-processos',
                        name: 'config.integra-robo-processos'
                    },
                    {
                        path: 'integra-detranPB',
                        name: 'config.integra-detranPB'
                    },
                    {
                        path: 'integra-grupoPorto',
                        name: 'config.integra-grupoPorto'
                    },
                    {
                        path: 'redefinir',
                        name: 'config.redefinir'
                    }
                ]
            },
            ...taxas,
            ...templates,
            ...variaveis,
            // ...usuario,
            ...sites,
            ...importador
        ]
    },
    {
        path: '/setup', component: () => import('./layout/SetupLayout.vue'), name: 'setup', children: [
            {
                path: '', component: () => import('./components/setup/Initiator'), name: 'setup.initiator'
            }
        ]
    }
]
